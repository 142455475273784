import * as React from "react";
import Contact from "../components/contact";
import Layout from "../components/layout";
import Offers from "../components/offers";

const KontaktePage = () => {
  return (
    <Layout>
      <h2 className="text-3xl font-bold text-left mb-4">Kontakt</h2>
      <Contact />
    </Layout>
  );
};

export default KontaktePage;

export const Head = () => <title>Kontakt</title>;
